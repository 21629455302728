<template>
  <div class="admin-message message-bubble __admin">
    <UserAvatar :user="data.user" />
    <div class="message-bubble-content">
      <p class="message-bubble-header">
        <span class="message-bubble-user" bold>{{ data.user.name }}</span>
        <span class="message-bubble-date">{{ date_locale(data.date) }}</span>
      </p>
      <p class="message-bubble-text" v-html="data.message"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss">
.message-bubble {
  display: flex;
  &-content {
    flex: 1;
  }
}
</style>
